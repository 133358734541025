/*global $*/
/*eslint no-undef: "error"*/

export default class Cookie {
    classBlock = 'cookie'
    classBlockActive = 'cookie-active'
    classButton = 'cookie__button'

    constructor () {
        let self = this

        // устанавливаем занчения по умолчанию
        if ( typeof(localStorage.cookieBlock) === 'undefined') {
            $(`.${self.classBlock}`).addClass(self.classBlockActive)
        }

        $(`.${self.classButton}`).click(function () {
            localStorage.cookieBlock = 1
            $(`.${self.classBlock}`).removeClass(self.classBlockActive)
        })
    }
}
