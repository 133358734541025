<template lang="pug">
splide(:options="options")
  splide-slide(
    v-for="(slot, key) of $slots"
    :key="key"
  )
    slot(:name="key")
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  name: 'PhotoSlider',

  components: {
    Splide,
    SplideSlide
  },

  data () {
    return {
      options: {
        rewind: true,
        pagination: false,
        perPage: 3,
        gap: '1rem',
        arrows: true,
        autoplay: false,
        breakpoints: {
          1024: {
            perPage: 3
          },
          768: {
            perPage: 2
          },
          640: {
            perPage: 2
          },
          480: {
            perPage: 2
          },
          414: {
            perPage: 1
          },
          375: {
            perPage: 1
          },
          360: {
            perPage: 1
          }
        }
      }
    }
  }
}
</script>
