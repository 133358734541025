/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderFixed {
    classHeader = 'header'
    classBody = 'body'


    constructor () {
        const self = this

        function bodyPadding () {
            const width = parseInt($(window).width())
            if (width <= 640) {
                const heightHeader = parseInt($(`.${self.classHeader}`).height()) + 20
                $(`${self.classBody}`).css('padding-top', heightHeader)
            } else {
                $(`${self.classBody}`).css('padding-top', 0)
            }
        }

        $(document).ready(() => {
            bodyPadding()
            $(window).resize(() => {
                bodyPadding()
            })
        })
    }
}
