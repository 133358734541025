/*global $*/
/*eslint no-undef: "error"*/

export default class TableBlocks {
    classTag = 'table-tag-item'
    classTagActive = 'table-tag-item-active'
    classTable = 'table-blocks__table'
    classTableActive = 'table-blocks__table-active'

    constructor () {
        const self = this

        $(`.${self.classTag}`).click(function () {
            let number = $(this).data("number")

            $(`.${self.classTag}`).removeClass(self.classTagActive)
            $(this).toggleClass(self.classTagActive)

            $(`.${self.classTable}`).removeClass(self.classTableActive)
            $(`.${self.classTable}[data-number="${number}"]`).addClass(self.classTableActive)
        })
    }
}
