/*global $*/
/*eslint no-undef: "error"*/

export default class BlockMore {
    classButton = 'block__more__button-wrapper'
    classBlock = 'block__more__content'
    classBlockActive = 'block__more__content-active'

    constructor () {
        const self = this
        let objButton = $(`.${self.classButton}`)
        let objBlock = $(`.${self.classBlock}`)

        objButton.click(function () {
            objBlock.toggleClass(self.classBlockActive)

            if (objBlock.hasClass(self.classBlockActive)) {
                objButton.text(objButton.data('do'))
            } else {
                objButton.text(objButton.data('to'))
            }
        })
    }
}
