/*global $*/
/*eslint no-undef: "error"*/

export default class City {
    classButton = 'popup-city__link'
    classCityName = 'city-button__name'

    constructor () {
        let self = this

        // устанавливаем занчения по умолчанию
        if ( typeof(localStorage.currentCity) === 'undefined') {
            localStorage.currentCity = 'Москва'
        }
        $(`.${self.classCityName}`).text(localStorage.currentCity)

        $(`.${self.classButton}`).click(function () {
            localStorage.currentCity = $(this).text()
            $(`.${self.classCityName}`).text(localStorage.currentCity)
        })
    }
}
