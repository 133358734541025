/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonScroll {
    classButton = 'button-top'

    constructor () {
        const self = this
        let objButton = $(`.${self.classButton}`)

        if (parseInt($(window).scrollTop()) < 100) {
            objButton.hide()
        }

        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                objButton.fadeIn()
            }else {
                objButton.fadeOut()
            }
        })

        objButton.click(function(){
            $('html, body').animate({ scrollTop: 0 }, 600);
            return false;
        })
    }
}
