import { createStore } from 'vuex'
// import Vue from 'vue'

export default createStore({
  state: {
    basePath: 'https://almaznaya-rezka77.ru',
    send: false
  },
  mutations: {
  },
  actions: {
    async Send (context, payload) {
      console.log(process.env.NODE_ENV)
      if ((process.env.NODE_ENV === 'development')) {
        console.log(payload)
      } else {
        const fields = {
          title: payload.setting.title,
          subject: payload.setting.subject,
          fields: payload.fields
        }

        const formData = new FormData()
        formData.append('fields', JSON.stringify(fields))

        if (payload.files !== '') {
          for (let i = 0; i < payload.files.length; i++) {
            let file = payload.files[i]
            formData.append('files[' + i + ']', file)
          }
        }

        await fetch(`/api/v1/send_form/`, {
          method: 'POST',
          body: formData
        })
      }
    }
  },
  modules: {
  },

  getters: {
    isSend: s => s.send
  }
})
