/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderMobile {
    classButtonOpen = 'header-mobile-button'
    classHeader = 'header-mobile'
    classHeaderActive = 'header-mobile-active'
    classBodyOverflow = 'overflow'

    constructor () {
        const self = this

        $(`.${self.classButtonOpen}`).click(function () {
            $(document).scrollTop(0)
            $(`.${self.classHeader}`).toggleClass(self.classHeaderActive)
            $(`body`).toggleClass(self.classBodyOverflow)
        })
    }
}
