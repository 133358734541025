/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonFormPopup {
    classButton = 'button-form-popup'
    classTitle = 'h2-form-popup'

    constructor () {
        const self = this

        $(`.${self.classButton}`).click(function () {
            let title = $(this).data('title')
            $(`.${self.classTitle}`).text(title)
        })
    }
}
