import { createApp } from 'vue'
import Maska from 'maska'
// import router from './router'
import store from './store'
import GallerySlider from '@/components/Slider/GallerySlider'
import PhotoSlider from '@/components/Slider/PhotoSlider'
import ReviewSlider from '@/components/Slider/ReviewSlider'
import PopupForm from '@/components/Form/PopupForm'
import AppFormSlider from '@/components/Form/AppFormSlider'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import TableBlocks from '@/assets/js/TableBlocks/TableBlocks'
import FaqItem from '@/assets/js/FaqItem/FaqItem'
import BlockMore from '@/assets/js/BlockMore/BlockMore'
import ButtonFormPopup from '@/assets/js/ButtonFormPopup/ButtonFormPopup'
import ScrollTab from '@/assets/js/Scroll/ScrollTab'
import HeaderMobile from '@/assets/js/HeaderMobile/HeaderMobile'
import MenuMobile from '@/assets/js/MenuMobile/MenuMobile'
import ButtonScroll from '@/assets/js/Scroll/ButtonScroll'
import HeaderFixed from '@/assets/js/HeaderFixed/HeaderFixed'
import City from '@/assets/js/City/City'
import Cookie from '@/assets/js/Cookie/Cookie'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

// Подключаем fancybox
require('@fancyapps/fancybox')

$(document).ready(function () {
    new TableBlocks()
    new FaqItem()
    new BlockMore()
    new ButtonFormPopup()
    new ScrollTab()
    new HeaderMobile()
    new MenuMobile()
    new ButtonScroll()
    new HeaderFixed()
    new City()
    new Cookie()
    new ShowHidden()
    new VideoImg()

    // формы
    if ($(`#form-popup`).length > 0) {
        createApp(PopupForm).use(form).use(Maska).mount('#form-popup')
    }
    if ($(`#form-slider`).length > 0) {
        createApp(AppFormSlider).use(form).use(Maska).mount('#form-slider')
    }
    // слайдер
    if ($(`#photo-list`).length > 0) {
        createApp({
            components: {
                'gallery-item': PhotoSlider
            }
        })
            .use(store)
            // .use(router)
            .mount('#photo-list')
        // createApp(GallerySlider).use(store).use(router).mount('#gallery-list')
    }
    if ($(`#gallery-list`).length > 0) {
        createApp({
            components: {
                'gallery-item': GallerySlider
            }
        })
            .use(store)
            // .use(router)
            .mount('#gallery-list')
        // createApp(GallerySlider).use(store).use(router).mount('#gallery-list')
    }
    if ($(`#review-list`).length > 0) {
        createApp({
            components: {
                'review-item': ReviewSlider
            }
        })
            .use(store)
            // .use(router)
            .mount('#review-list')
    }
})
