/*global $*/
/*eslint no-undef: "error"*/

export default class ScrollTab {
    classItem = 'scroll-tag-item'

    constructor () {
        const self = this

        $(`.${self.classItem}`).click(function (e) {
            let block = $(this).data('block')
            $('html,body').stop().animate({ scrollTop: $(`${block}`).offset().top }, 500)
            e.preventDefault()
            return false
        })
    }
}
