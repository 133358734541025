<template lang="pug">
.form-slider
    div(v-if="!send")
      .form-slider__title Заполните форму и получите расчет со скидкой 10%
      .form-slider__blocks
        .form-slider__block
          .form-slider__sub Вид работ
          .form-slider__props
              .form-slider__prop
                  label.input-check
                    input.input-check__input(type="checkbox" v-model="fieldProp1" value="алмазная резка")
                    span.input-check__button
                      svg.input-check__icon
                        use(xlink:href="#icon-check")
                    span.input-check__value алмазная резка

              .form-slider__prop
                  label.input-check
                    input.input-check__input(type="checkbox" v-model="fieldProp1" value="алмазное бурение")
                    span.input-check__button
                        svg.input-check__icon
                          use(xlink:href="#icon-check")
                    span.input-check__value алмазное бурение

        .form-slider__block
          .form-slider__sub Вид материала
          .form-slider__props
              .form-slider__prop
                  .form-slider__prop
                    label.input-check
                      input.input-check__input(type="checkbox" v-model="fieldProp2" value="бетон")
                      span.input-check__button
                          svg.input-check__icon
                            use(xlink:href="#icon-check")
                      span.input-check__value бетон

              .form-slider__prop
                  .form-slider__prop
                      label.input-check
                        input.input-check__input(type="checkbox" v-model="fieldProp2" value="железобетон")
                        span.input-check__button
                            svg.input-check__icon
                              use(xlink:href="#icon-check")
                        span.input-check__value железобетон

              .form-slider__prop
                  .form-slider__prop
                      label.input-check
                        input.input-check__input(type="checkbox" v-model="fieldProp2" value="кирпич")
                        span.input-check__button
                            svg.input-check__icon
                              use(xlink:href="#icon-check")
                        span.input-check__value кирпич

        .form-slider__block
          .form-slider__sub Толщина (см)
          .input-text
            input.input-text__input(type="text" placeholder="Ваше значение" v-model="fieldWeight")

        .form-slider__block
          .form-slider__sub Номер телефона *
          .input-text
              input.input-text__input(type="text" placeholder="Ваш телефон *" v-model="fieldPhone" v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']")

      .form-slider__consent
          .consent
            .consent__wrapper
                input.consent__input(type="checkbox" id="popup-form-slider" checked="checked")
                label.consent__label(
                    for="popup-form-slider"
                ) Отправляя данную форму, Вы даете свое согласие на <a :href="linkSogl">обработку персональных данных</a>.

      .form-slider__button
          .button.button-yellow(@click="getSend()" :class="{'button-disable': v$.$invalid}")
              .button__wrapper
                  .button__text Отправить
    div(v-else)
        .form-success
          .form-success__wrapper
              .form-success
                  p Ваше сообщение отравлено.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
    name: 'AppFormSlider',

    setup () {
        return { v$: useVuelidate() }
    },

    data () {
        return {
            fieldPhone: '',
            fieldWeight: '',
            fieldProp1: [],
            fieldProp2: [],
            files: '',
            send: false,
            linkSogl: '/polzovatelskoye-soglashenie.html'
        }
    },

    methods: {
        ...mapActions([
            'Send'
        ]),

        getSend () {
            if (!this.v$.$invalid) {
                this.send = !this.send
                this.Send({
                    setting: {
                        title: 'Заявка с формы',
                        subject: 'Заявка с формы "Расчет стоимости"'
                    },

                    fields: [
                        {
                            title: 'Телефон',
                            value: this.fieldPhone
                        },
                        {
                            title: 'Толщина (см)',
                            value: this.fieldWeight
                        },
                        {
                            title: 'Вид работ',
                            value: this.fieldProp1.toString()
                        },
                        {
                            title: 'Вид материала',
                            value: this.fieldProp2.toString()
                        }
                    ],
                    files: this.files
                })

                setTimeout(() => {
                    this.send = !this.send
                }, 4000)
            }
        }
    },

    validations () {
        return {
            fieldPhone: {
                required,
                minLength: minLength(18),
                maxLength: maxLength(18)
            }
        }
    }
}
</script>

