/*global $*/
/*eslint no-undef: "error"*/

export default class MenuMobile {
    classButton = 'menu-mobile__icon'
    classItem = 'menu-mobile__item'
    classItemActive = 'menu-mobile__item-active'

    classButtonSub = 'menu-mobile-sub__icon'
    classItemSub = 'menu-mobile-sub__item'
    classItemSubActive = 'menu-mobile-sub__item-active'

    constructor () {
        const self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemActive)
        })

        $(`.${self.classButtonSub}`).click(function () {
            $(this).closest(`.${self.classItemSub}`).toggleClass(self.classItemSubActive)
        })
    }
}
